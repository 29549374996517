/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap-grid.scss";
@import "bootstrap/scss/bootstrap-utilities.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

:root {
  --mdc-filled-text-field-container-color: rgb(231, 241, 253);
}
